// usercreation.js
import axios from 'axios';

export function askToCreateAccount() {
    return confirm("Do you want to save your information by creating a user account?");
}

export async function createUserAccount(sex, yearOfBirth) {
    const username = prompt("Enter a username:");
    const password = prompt("Enter a password:");
    
    // Send the data to your server to create the account
    try {
        const response = await axios.post('/signup', {
            username,
            password,
            year_of_birth: yearOfBirth,
            sex
        });
        if (response.data.success) {
            alert("User account created successfully!");
            return response.data.user_id;
        } else {
            alert("Failed to create user account.");
        }
    } catch (error) {
        console.error("Error creating user account:", error);
        alert("An error occurred while creating the account.");
    }
}

export async function handleLogin(username, password, userId) {
    try {
        const response = await axios.post('/login', { username, password });
        if (response.data.message === 'Login successful') {
            console.log("Login successful. User ID:", response.data.user_id);
            return { userId: response.data.user_id }; // Return the user ID from the response
        } else {
            alert("Login failed: " + response.data.error);
        }
    } catch (error) {
        console.error("Error logging in:", error);
        alert("An error occurred while logging in.");
    }
}

// Simulate the end of the vaccine process
function endOfVaccineProcess() {
    // Vaccine process logic here
    console.log("Vaccine process completed.");
    
    // Ask the user if they want to create an account
    askToCreateAccount();
}