import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import RecordRTC from 'recordrtc';
import { v4 as uuidv4 } from 'uuid';
import { askToCreateAccount, createUserAccount, handleLogin } from './usercreation';
import LoggedInPage from './LoggedInPage';

axios.defaults.baseURL = "https://cloudflare.luisa-3fe.workers.dev";

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [responses, setResponses] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false); // State to track completion
  const [recommendations, setRecommendations] = useState([]);
  const [userMetadata, setUserMetadata] = useState({});
  const [showCheckboxes, setShowCheckboxes] = useState(false); // State to track if checkboxes should be shown
  const [userId, setUserId] = useState(() => uuidv4()); // State to store user_id, initialized once
  const [loadingRecommendations, setLoadingRecommendations] = useState(false); // State to track loading recommendations
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track if user is logged in
  const canvasRef = useRef(null);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const recorder = useRef(null);

  useEffect(() => {
    const fetchFirstQuestion = async () => {
      try {
        const question = await axios.get('/firstquestion');
        if (question.data) {
          setCurrentQuestion(question.data);
        }
      }
      catch (error) {
        console.error('Error fetching first question:', error);
      }
    }
    fetchFirstQuestion();
  }, []);

  useEffect(() => {
    // Start recording automatically when a new question is loaded, except for the first question
    if (currentQuestion && !isCompleted && currentQuestion.question_id !== 1) {
      startRecording();
    }
  }, [currentQuestion]);

  const visualizeAudio = (stream) => {
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioContext.current.createMediaStreamSource(stream);
    analyser.current = audioContext.current.createAnalyser();
    analyser.current.fftSize = 64; // Adjust for fewer frequency bins

    const bufferLength = analyser.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const draw = () => {
      requestAnimationFrame(draw);

      analyser.current.getByteFrequencyData(dataArray);

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

      const avg = dataArray.reduce((a, b) => a + b) / bufferLength; // Average volume
      const radius = Math.min(centerX, centerY) * (avg / 128); // Radius scales with volume

      canvasCtx.beginPath();
      canvasCtx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      canvasCtx.fillStyle = `rgba(50, 150, 250, ${avg / 255})`; // Dynamic fill color
      canvasCtx.fill();
    };

    draw();
    source.connect(analyser.current);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorder.current = new RecordRTC(stream, {
        type: 'audio',  // Record audio
        mimeType: 'audio/webm',  // Specify a broadly compatible format
        recorderType: RecordRTC.MediaStreamRecorder,  // Ensures compatibility with most browsers
        disableLogs: false,  // Optional: remove logs for production
      });
      visualizeAudio(stream);
      recorder.current.startRecording();
      setIsRecording(true);
      console.log('Recording started');
      recognition.start();
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (!isRecording) return;  // Prevent multiple stop calls
    setIsRecording(false);
    console.log('Recording stopped');

    recognition.stop();
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        const blob = recorder.current.getBlob();
        console.log(`Final blob size: ${blob.size} bytes`);

        if (blob.size > 0) {
          submitResponse(blob);
        } else {
          console.error("Recording failed, blob is empty.");
        }
        recorder.current = null;  // Reset the recorder for future recordings
      });
    } else {
      console.error("Recorder not initialized");
    }
  };

  // Stop recording automatically when the user stops speaking
  recognition.onspeechend = () => {
    stopRecording();
  };

  recognition.onend = () => {
    // Handle auto-advancing here if necessary
    console.log("Recognition ended");
  };

  const submitResponse = async (audioBlob) => {
    const CurrentQuestionId = currentQuestion.question_id;
    const responseData = new FormData();
    responseData.append('response', audioBlob);
    responseData.append('user_id', userId); // Use the temporary user ID
    responseData.append('question_id', CurrentQuestionId);
    for (let pair of responseData.entries()) {
      console.log(`${pair[0]}:`, pair[1]);
    }
    try {
      const response = await axios.post('/submit', responseData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data) {
        console.log('Response submitted:', response.data);
        const newResponse = {
          question_id: CurrentQuestionId,
          response_id: response.data.response_id,
          answer: response.data.answer // Ensure the answer is included
        };
        setResponses([...responses, newResponse]);
        console.log('Responses:', [...responses, newResponse]);
        if (response.data.next_question_id === null) {
          setIsCompleted(true);
        } else {
          const fetchNextQuestion = await axios.get('/nextquestion', {
            params: {
              next_question_id: response.data.next_question_id,
              response_id: response.data.response_id
            }
          });
          setCurrentQuestion(fetchNextQuestion.data);
        }
      }
    }
    catch (error) {
      console.error('Error submitting response:', error);
    }
  };  

  useEffect(() => {
    if (isCompleted) {
        fetchRecommendations(responses);
    }
  }, [isCompleted]); // Runs only when isCompleted becomes true

  const fetchRecommendations = async (responses) => {
    try {
      setLoadingRecommendations(true);
      const sexResponse = responses.find(response => response.question_id === 1);
      const yearOfBirthResponse = responses.find(response => response.question_id === 3);

      const sex = sexResponse.answer;
      const yearOfBirth = yearOfBirthResponse.answer;

      // Fetch recommendations with the temporary user ID
      const recommendations = await axios.get('/recommendations', {
        params: {
          responses: JSON.stringify(responses),
          user_id: userId
        }
      });
      if (recommendations.data) {
        setRecommendations(recommendations.data);
      }
      setLoadingRecommendations(false);

      // Ask the user if they want to create an account
      const userWantsAccount = askToCreateAccount();
      if (userWantsAccount) {
        const newUserId = await createUserAccount(sex, yearOfBirth);
        console.log('New user ID:', newUserId);
        if (newUserId) {
          setUserId(newUserId); // Set the new user ID
          // Update responses with the new user ID
          const updatedResponses = responses.map(response => ({
            ...response,
            user_id: newUserId
          }));
          setResponses(updatedResponses);
          setUserMetadata(prevMetadata => ({
            ...prevMetadata,
            user_id: newUserId
          }));
          // Send updated responses to the backend
          await axios.post('/update_responses', { responses: updatedResponses, new_user_id: newUserId });
          setShowCheckboxes(true);
        }
      } else {
        // User chose not to create an account, set user_id to 0
        const updatedResponses = responses.map(response => ({
          ...response,
          user_id: 0
        }));
        setResponses(updatedResponses);
        setUserMetadata(prevMetadata => ({
          ...prevMetadata,
          user_id: 0
        }));
        setShowCheckboxes(false); // Hide checkboxes if user does not create an account
      }
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setLoadingRecommendations(false);
    }
  };

  const sendVaccines = async (event) => {
    event.preventDefault();
    const vaccines = Object.keys(userMetadata).map(vaccineName => ({
      user_id: userId,
      vaccine_name: vaccineName,
      vaccine_status: userMetadata[vaccineName]?.status || false,
      vaccine_date: userMetadata[vaccineName]?.date || null
    }));
    try {
      const response = await axios.post('/send_vaccines', { vaccines });
      if (response.data) {
        console.log('Vaccines sent:', response.data);
      }
    }
    catch (error) {
      console.error('Error sending vaccines:', error);
    }
  }

  const handleUserLogin = async () => {
    const username = prompt("Enter your username:");
    const password = prompt("Enter your password:");
    const loginSuccess = await handleLogin(username, password);
    console.log("Login successful", loginSuccess.userId);
    if (loginSuccess) {
      setIsLoggedIn(true);
      setUserId(loginSuccess.userId); // Set the user ID after successful login
    }
  }

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserId(uuidv4()); // Reset user ID to a new temporary ID
    setResponses([]);
    setRecommendations([]);
    setUserMetadata({});
    setShowCheckboxes(false);
  };

  return (
    <div className="App">
      <h1>Vaccine Needs Assessment</h1>
      {isLoggedIn ? (
        <LoggedInPage userId={userId} onLogout={handleLogout} />
      ) : (
        <>
          <button onClick={handleUserLogin}>Login</button>
          {!currentQuestion ? (
            <p>Loading question...</p>
          ) : isCompleted ? (
            <>
              <h2>Survey Completed</h2>
              <h3>Recommendations:</h3>
              {loadingRecommendations ? (
                <p>Loading recommendations...</p>
              ) : showCheckboxes ? (
                <form onSubmit={sendVaccines}>
                  {recommendations.map((recommendation, index) => (
                    <li key={index}>
                      <label>
                        <input
                          type="checkbox"
                          value={recommendation[0]}
                          onChange={(e) => {
                            const updatedUserMetadata = {
                              ...userMetadata,
                              [recommendation[0]]: {
                                ...userMetadata[recommendation[0]],
                                status: e.target.checked
                              }
                            };
                            setUserMetadata(updatedUserMetadata);
                          }}
                        />
                        <strong>{recommendation[0]}</strong>: {recommendation[1]} - {recommendation[2]}
                      </label>
                      {userMetadata[recommendation[0]]?.status && (
                        <input
                          type="date"
                          onChange={(e) => {
                            const updatedUserMetadata = {
                              ...userMetadata,
                              [recommendation[0]]: {
                                ...userMetadata[recommendation[0]],
                                date: e.target.value
                              }
                            };
                            setUserMetadata(updatedUserMetadata);
                          }}
                        />
                      )}
                    </li>
                  ))}
                  <button type="submit">Send Vaccines</button>
                </form>
              ) : (
                <ul>
                  {recommendations.map((recommendation, index) => (
                    <li key={index}>
                      <strong>{recommendation[0]}</strong>: {recommendation[1]} - {recommendation[2]}
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <>
              <h2>Question {currentQuestion.question_id}</h2>
              <p>{currentQuestion.question_text}</p>
              
              {currentQuestion.options?.length > 0 && (
                <ul>
                  {currentQuestion.options.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              )}

              <div>
                <button onClick={startRecording} disabled={isRecording}>
                  {isRecording ? 'Recording...' : 'Start Recording'}
                </button>
                <button onClick={stopRecording} disabled={!isRecording}>
                  Stop Recording
                </button>
              </div>

              <div>
                <h2>Audio Input Visualization</h2>
                <canvas ref={canvasRef} width="300" height="300" />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default App;