// LoggedInPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LoggedInPage = ({ userId, onLogout }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [userMetadata, setUserMetadata] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await axios.get('/user_vaccines', {
          params: {
            user_id: userId
          }
        });
        console.log('Response:', response.data);
        if (Array.isArray(response.data)) {
          setRecommendations(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, [userId]);

  const handleSave = async () => {
    try {
      const vaccines = Object.keys(userMetadata).map(vaccineName => ({
        user_id: userId,
        vaccine_name: vaccineName,
        vaccine_status: userMetadata[vaccineName]?.status || false,
        vaccine_date: userMetadata[vaccineName]?.date || null
      }));
      const response = await axios.post('/send_vaccines', { vaccines });
      if (response.data) {
        alert('Vaccines updated successfully!');
      }
    } catch (error) {
      console.error('Error updating vaccines:', error);
      alert('An error occurred while updating vaccines.');
    }
  };

  if (loading) {
    return <p>Loading recommendations...</p>;
  }

  return (
    <div>
      <h2>Your Recommended Vaccines</h2>
      <button onClick={onLogout}>Logout</button>
      <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        {recommendations.map((recommendation, index) => (
          <li key={index}>
            <label>
              <input
                type="checkbox"
                value={recommendation.vaccine_name}
                checked={userMetadata[recommendation.vaccine_name]?.status || false}
                onChange={(e) => {
                  const updatedUserMetadata = {
                    ...userMetadata,
                    [recommendation.vaccine_name]: {
                      ...userMetadata[recommendation.vaccine_name],
                      status: e.target.checked
                    }
                  };
                  setUserMetadata(updatedUserMetadata);
                }}
              />
              <strong>{recommendation.vaccine_name}</strong>: {recommendation.vaccine_status} - {recommendation.vaccine_date}
            </label>
            {userMetadata[recommendation.vaccine_name]?.status && (
              <input
                type="date"
                value={userMetadata[recommendation.vaccine_name]?.date || ''}
                onChange={(e) => {
                  const updatedUserMetadata = {
                    ...userMetadata,
                    [recommendation.vaccine_name]: {
                      ...userMetadata[recommendation.vaccine_name],
                      date: e.target.value
                    }
                  };
                  setUserMetadata(updatedUserMetadata);
                }}
              />
            )}
          </li>
        ))}
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default LoggedInPage;